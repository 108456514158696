import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

// const startAgain = () => {

//   const userIdentifier = localStorage.getItem('userIdentifier');
//   postUserIdentifierBeacon(userIdentifier);
//   console.log('posted useridentifier', userIdentifier);

//   localStorage.clear();
//   console.log('Console cleared');
  
//   // Clear local storage
  
  
//   // Add clearLocalStorage=true parameter to iframeSrcUrl
//   const updatedIframeSrcUrl = `${iframeSrcUrl}&clearLocalStorage=true`;
  
//   const iframe = document.getElementById('super_embed');

//   // Define a function to handle page reload
//   const reloadPage = () => {
//       const currentUrl = window.location.href;
//       // Reload the page with the updated URL
//       window.location.href = currentUrl.toString();
//   };

//   // Set onload event listener for the iframe
//   iframe.onload = () => {
//       const userIdentifier = localStorage.getItem('userIdentifier');
//       postUserIdentifierBeacon(userIdentifier);
//       console.log('posted useridentifier', userIdentifier);
//       // Call reloadPage function after iframe finishes loading
//       reloadPage();
//   };

//   // Reload the iframe content with the updated URL
//   iframe.src = updatedIframeSrcUrl;
// };

const Navbar = () => {

  return (
    <nav className="bg-consilium-primary p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img 
            src="/consilium_logo.png" 
            alt="Logo" 
            className="h-8 md:h-10 lg:h-12"
          />
        </Link>
        {/* Start Again Button */}
        {/* <button
          onClick={startAgain}
          className="bg-transparent hover:bg-consilium-darker text-consilium-text hover:text-white py-2 px-4 border border-consilium-text hover:border-transparent rounded"
        >
          Start Again
        </button> */}
      </div>
    </nav>
  );
};

export default Navbar;
