import React, { useEffect } from 'react';

const getAddressDomainToken = 'Vkxbhjby00-XzuyhwFp7BA43108';



const AddressFields = ({ userInput, setUserInput }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.getaddress.io/scripts/getaddress-autocomplete-1.3.4.min.js';
    script.async = true;
    script.onload = () => {
      if (window.getAddress) {
        window.getAddress.autocomplete('formatted_address_0', getAddressDomainToken);
      }
    };
    document.body.appendChild(script);

    const handleAddressSelected = (e) => {
      console.log('selected address:', e);
      const address = e.address
      const formattedAddress = address.formatted_address;
      // const { formatted_address_0, formatted_address_1, town_or_city, county, postcode } = e.address;
      const formatted_address_0 = formattedAddress[0];
      const formatted_address_1 = formattedAddress[1];
      const town_or_city = address.town_or_city;
      const county = address.county;
      const postcode = address.postcode;

      // save to storage now?
      let userParams = JSON.parse(localStorage.getItem('userParams')) || {};
      userParams['fullAddress'] = JSON.stringify(address);
      localStorage.setItem('userParams', JSON.stringify(userParams));

      setUserInput({
        formatted_address_0,
        formatted_address_1,
        town_or_city,
        county,
        postcode,
      });
    };

    document.addEventListener('getaddress-autocomplete-address-selected', handleAddressSelected);

   
    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
      document.removeEventListener('getaddress-autocomplete-address-selected', handleAddressSelected);
    };
  }, [setUserInput]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    console.log('update:', id, value);
    setUserInput((prevInput) => ({
      ...prevInput,
      [id]: value,
    }));
    console.log('userInput', userInput);
  };


  return (
    <div className="top-2/3 w-full flex flex-col items-center space-y-6">
      {/* <label>First Address Line</label> */}
      <input
        id="formatted_address_0"
        type="text"
        value={userInput.formatted_address_0 || ''}
        onChange={handleInputChange}
        className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
        placeholder='Address 1'
      />

      {/* <label>Second Address Line</label> */}
      <input
        id="formatted_address_1"
        type="text"
        value={userInput.formatted_address_1 || ''}
        onChange={handleInputChange}
        className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
        placeholder="Address 2"
      />

      {/* <label>Town</label> */}
      <input
        id="town_or_city"
        type="text"
        value={userInput.town_or_city || ''}
        onChange={handleInputChange}
        className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
        placeholder="Town"
      />

      {/* <label>County</label> */}
      <input
        id="county"
        type="text"
        value={userInput.county || ''}
        onChange={handleInputChange}
        className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
        placeholder="County"
      />

      {/* <label>Postcode</label> */}
      <input
        id="postcode"
        type="text"
        value={userInput.postcode || ''}
        onChange={handleInputChange}
        className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
        placeholder="Postcode"
      />
    </div>
  );
};

export default AddressFields;
